import axios from 'axios'

const hostname = window.location.hostname
const apiUrl = process.env.REACT_APP_API_URL;

const Apiv3 = axios.create({ baseURL: apiUrl })

Apiv3.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
//Apiv3.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
Apiv3.defaults.withCredentials = true;

export default Apiv3
